@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

button.slick-arrow.slick-prev {
  width: 30px;
  height: 30px;
  /* background: #ffa726; */
  display: block;
  position: absolute;
  left: -8px;
  z-index: 50;
}
.slick-prev:before {
  color: #ffa726 !important;
  font-size: 2rem;
}

button.slick-arrow.slick-next {
  width: 30px;
  height: 30px;
  /* background: #ffa726; */
  display: block;
  position: absolute;
  right: 2px;
  z-index: 50;
}
.slick-next:before {
  padding: 15px;
  color: #4caf50 !important;
  font-size: 2rem;
}
