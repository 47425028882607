$primary: #0f87ca;
$success: #4caf50;
$warning: #ffa726;
// $danger: #e53935;
$danger: #f44336;
$white: #fff;
$power-blue: #e0e8f0;

$bg-icon-purple: #9a6fd6;
$bg-icon-steel-blue: #4c76a8;
$bg-icon-muted-cyan: #568f99;

$background-primary: #4caf50;

$font-family: "Poppins", sans-serif;

// :export {
//   primary: $primary-color;
// }
