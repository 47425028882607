@import './src/app/variables';
.card {
  background: $power-blue;
  display: flex;
  align-items: center;
  border: unset;
  border-radius: 10px;
  height: 100%;
  max-height: 500px;
  padding: 20px 10px 10px 10px;

  .icon {
    background: #fff;
    border-radius: 50px;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    /* .icon > p {
      background: #fff;
      border-radius: 50px;
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    } */
  }

  .heading {
    font-size: 1.25rem;
    font-weight: 400;
  }

  p {
    color: #557087;
    font-size: 0.9rem;
    text-align: center;
  }
}

.sectionThree {
  width: 100% !important;

  .featureCard {
    padding: 30px 5px;
    border-radius: 50px;
    height: 100%;
    max-height: 700px;

    .featureList {
      list-style: none;

      li {
        font-size: 18px;
        font-weight: 700;
        line-height: 34.5px;
        margin-bottom: 25px;

        &.positive {
          position: relative;
          padding-left: 40px; /* Add padding to make room for the icon */

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            transform: translateY(20%);
            width: 25px;
            height: 25px;
            background-image: url("/icons/check.svg"); /* Path to the icon in the `public` folder */
            background-size: contain; /* Ensure it scales correctly */
            background-repeat: no-repeat;
          }
        }

        &.negative {
          position: relative;
          padding-left: 40px; /* Add padding to make room for the icon */

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            transform: translateY(20%);
            width: 22px; /* Adjust width of the icon */
            height: 22px; /* Adjust height of the icon */
            background-image: url("/icons/cross.svg"); /* Path to the icon in the `public` folder */
            background-size: contain; /* Ensure it scales correctly */
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .bgGreen {
    color: $white;
    background-color: $success;
    border: 3px solid;
    border-image-source: linear-gradient(
      180deg,
      rgba(237, 237, 237, 0.2) 0%,
      rgba(158, 243, 0, 0.2) 100%
    );
  }

  .bgWarning {
    color: $white;
    background: $warning;
    border: 3px solid;
    border-image-source: linear-gradient(180deg, #e9e9e9 0%, #ff8282 100%);
  }
}

.container {
  max-width: 100%;
  padding: 15px;
}

.testimonialCard {
  border: 0.64px solid #eff0f6;
  border-radius: 12px;
  border: none;
  padding: 0.5rem !important;
  box-shadow: var(--bs-box-shadow-sm) !important;
  padding: 30px 15px !important;
  min-height: 280px;
}

.testimonialCardTitle {
  font-size: 0.9rem;
  color: #170f49;

  font-weight: 700;
}
.testimonialCardDes {
  color: #6f6c90;
  font-size: 0.72rem;
}

.testimonialPic {
  background: #e6e4ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  margin-right: 10px;
}
.testimonialName {
  font-size: 0.75rem;
  font-weight: 700;
  color: #1d1e20;
}

.testimonialText {
  background: #e6e4ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  margin-right: 10px;
}

.buttonsFlex {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
    padding: 15px;
  }

  .card {
    border-radius: 10px;
    padding: 20px 0px 10px 0px;
  }

  .sectionThree {
    .featureCard {
      padding: 10px 5px;
      .featureList {
        li {
          font-size: 18px;
        }
      }
    }
  }

  .buttonsFlex {
    align-items: start;
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
    padding: 15px;
  }

  .card {
    border-radius: 10px;
    padding: 50px 20px 20px 10px;
  }

  .sectionThree {
    .featureCard {
      padding: 80px 40px;
      .featureList {
        li {
          font-size: 23px;
        }
      }
    }
  }

  .buttonsFlex {
    align-items: start;
    flex-direction: row;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1180px;
    padding: 15px;
  }

  .sectionThree {
    width: 1000px !important;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1180px;
  }
}
