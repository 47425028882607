.footerBottom {
  background-color: rgb(15, 135, 202);
}

.footerList {
  list-style: none;
}

.footerLink {
  font-size: 14px;
}

.footerSocialIcon {
  background: black;
  padding: 5px 7px 7px 7px;
  border-radius: 50%;
  margin-right: 8px;
}

.footerSocialIcon svg {
  color: white;
  font-size: 20px;
}